<template>
<div class="mt-12">
  <v-container class="mt-12 text-center">
    <v-img class="mb-12 mx-auto" src="https://examenselectivitat.cat:3000/api/images/other/wallet.png" max-width="106px"></v-img>
    <h2>Hi ha hagut un problema amb el pagament. <br> Torna-ho a intentar amb una altra targeta.</h2>
  </v-container>
</div>
</template>

<script>
export default {
  name: "Success"
}
</script>

<style scoped>

</style>
